$purple-dark: rgb(99, 59, 74);

$grey-light: rgb(192, 192, 192);
$grey-lightest: rgb(240, 240, 240);
$grey: rgb(128, 128, 128);
$grey-dark: rgb(51, 51, 51);
$grey-darkest: rgb(31, 31, 31);
$grey-medium-dark:rgb(102, 102, 102);
$grey-light-dark: rgb(153, 153, 153);
$grey-medium-button-text: rgb(198, 198, 198);
$grey-light-button: rgb(206, 205, 205);
$grey-button-disabled: rgb(214, 214, 214);
$grey-button-disabled-text: rgb(172, 172, 172);
$grey-light-medium-dark: rgb(217, 217, 217);
$transparent-grey: rgba(221, 221, 221, 0.5);
$button-grey: rgb(239, 239, 239);

$button-light-grey: #EFEFEF;
$button-hover-grey: #5F5F5F;

$back-button: rgba(206, 205, 205, 0.34);
$back-button-hover: rgba(156, 156, 156, 0.34);

$color-darkener: rgba(0, 0, 0, 0.15);
$color-mid-darkener: rgba(0, 0, 0, 0.5);
$color-extra-darkener: rgba(0, 0, 0, 0.8);

$color-background: $grey-lightest;
$color-border: $color-darkener;
$color-footer: $grey-darkest;
$color-footer-link: $grey-light-dark;
$color-footer-link-hover: $grey-light;
$color-shadow: $color-darkener;

$online-color: rgb(85, 239, 196);
$text-highlight: #B5FFE7;
$inperson-color: rgb(116, 185, 255);
$conversation-color: rgb(255, 234, 167);
$tuition-color: rgb(250, 177, 160);
$group-color: rgb(223, 230, 233);
$language-culture: rgb(190, 144, 212);

// This is the color from the color codes given by Cristina

$white: rgb(255, 255, 255);
$white-transparent: #ffffff80;
$white-yellow: rgb(252, 248, 227);
$white-red: rgb(242, 222, 222);
$white-blue: rgb(217, 237, 247);

$teal: #7fc7bd;
$soft-teal: lighten($teal, 50%);

$blue: #397988;
$soft-blue: lighten($blue, 20%);
$dark-blue: darken($blue, 20%);
$blue-light: rgb(217, 237, 247);
$blue-opacity: rgba(57, 121, 136, 0.8);

$peach: #e7ce9b;
$soft-peach: lighten($peach, 10%);
$smooth-peach: #F5E6CD;

$green: #A5CF65;
$soft-green: lighten($green, 20%);
$dark-green: #1a9c6f;

$yellow: #e6d255;
$soft-yellow: rgb(231, 206, 155);
$transparent-yellow: rgba(231,206,155, 0.5);

$orange: #EF9349;
$soft-orange: rgb(239, 147, 73);
$very-soft-orange: rgb(138, 109, 59);

$pink: #E34B60;
$soft-pink: lighten($pink, 10%);
$medium-pink: #FA5B5B;

$red: #a60a0d;
$soft-red: rgb(227, 75, 96);
$red-dark: rgb(169, 68, 66);
$red-light: rgb(242, 222, 222);

$purple: rgb(134, 86, 169);
$soft-purple: rgb(118, 102, 153);

// Colors from app refresh

$salmon: #FC7979;
$purple-heart: #462AB9;
$light-gray: #7d7d7d;
$blumine: #2e6775;
$white-smoke: #f0f0f0;
$whisper: #e6e6e6;
$gainsboro: #E3E3E3;
$black: #000000;
$dark-grey: #333333;
$active-grey: #F5F5F5;
$inactive-grey: #CCCCCC;
$mint: #69E6bb;
$mint-opacity: rgba(66, 209, 159, 0.11);
$extra-soft-mint: lighten($mint, 26%);
$soft-mint: lighten($mint, 20%);
$medium-mint: #66E7BD;
$dark-mint: darken($mint, 10%);
$extra-dark-mint: darken($mint, 20%);
$camel: #F5EBD8;
$camel-2: #F2E5CB;
$tooltip-highlight: #FCD889;
$new-light-gray: #F4F4F4;
$gray85: #D9D9D9;
$link-default: #4E65DE;
$link-hover: #6980FC;
$link-active: #384BAC;
$salmon: #FFCDC7;

$border-grey: #C9C9C9;
