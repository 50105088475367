@import "./utils/colors";
@import url("https://fonts.googleapis.com/css?family=Work Sans");
@import url("https://fonts.googleapis.com/css?family=Karla");

*,
*::before,
*::after {
  margin: 0;
}

$small: 16px;
$medium: 24px;
$large: 60px;

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  transform-origin: 0 0;

  display: flex;
  flex-direction: column;
  background-color: $white;
  font-family: "Work sans", sans-serif;
  color: $dark-grey;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 10px;
  padding-left: 150px;
  padding-left: 40px;
  height: 70px;
  border-bottom: 1px solid $transparent-grey;

  &__logo {
    width: 200px;
    margin: 0px;
  }
}

.main {
  flex: 1 0 auto;
  margin-bottom: auto;

  display: flex;
  height: 100%;
}

.side-picture {
  top: 0;
  height: 100vh;
  width: 100%;

  border-left: 50px solid $mint;
  object-position: 55% 50%;
  object-fit: cover;
  position: sticky;
}

.form-container {
  transform: scale(0.8);
  margin-top: -60px;
  max-width: 80%;
  left: -4%;

  display: inline-block;
  text-align: left;
  position: relative;

  @media screen and (width < 1250px) {
    margin-top: -10px;
    transform: scale(0.9);
    max-width: 100%;
    margin-bottom: 50px;
  }
  @media screen and (width < 800px) {
    transform: scale(1);
    max-width: 100%;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 100px;
  }
  @media screen and (width < 600px) {
    margin-right: 20px;
  }
}

h1 {
  font-size: $large;
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: -0.04em;
  margin-top: 70px;

  @media screen and (width < 1250px) {
    font-size: 50px;
    margin-top: 30px;
  }
  @media screen and (width < 800px) {
    font-size: 34px;
    margin-top: 30px;
  }
  @media screen and (width < 600px) {
    white-space: normal;
  }

  &.success {
    padding-top: 50px;
    font-size: 50px;

    @media screen and (width < 1250px) {
      font-size: 34px;
    }
    @media screen and (width < 800px) {
      font-size: 30px;
    }
  }
}

.max-width {
  max-width: 700px;
}

.subheading {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: $small;
  line-height: 140%;
  margin-top: 25px;
}

h2 {
  font-weight: bold;
  font-size: medium;

  margin-top: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: $medium;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.checkbox-label {
  margin-top: 40px;
  display: table;
}

.checkbox-wrapper {
  height: 100%;
  display: table-cell;
  vertical-align: top;
}

.footer {
  left: 0;
  bottom: 0;
  background-color: $grey-lightest;
  color: $dark-grey;
  text-align: center;
  padding: 30px;
  margin-top: auto;

  a {
    color: $dark-grey;
  }
}

:export {
  white: $white;
  mint: $mint;
  extraSoftMint: $extra-soft-mint;
  extraDarkMint: $extra-dark-mint;
  transparentGrey: $transparent-grey;
  darkGrey: $dark-grey;
  darkGreyDisabled: lighten($dark-grey, 50%);
  borderGrey: rgb(221, 221, 221);
  buttonGrey: $button-grey;
  buttonHoverGrey: $button-hover-grey;
  small: $small;
}
